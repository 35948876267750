.banner .hero-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 598px;
  margin-left: auto;
}

.hero-img {
  width: 50%;
  padding-left: 2%;
}

.hero-img img {
  width: 100%;
  max-width: 100%;
}

.banner {
  padding: 25px 0 6%;
}

.banner .hero-content p.para {
  padding: 10px 0 30px 0;
}

.crypto-card {
  padding: 24px 32px;
  background: rgba(34, 36, 39, 0.75);
  border-radius: 12px;
}

section.trading-slide {
  display: flex;
  flex-wrap: wrap;
  margin: -50px 0 0 0;
  padding: 0 0 6% 0;
}

.trading-slide .swiper-pagination {
  bottom: 30px;
}

.trading-slide .swiper {
  padding: 0px 0 80px 0;
}

.crypto-card .card-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.crypto-card .card-content h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #f5f5f5;
}

.crypto-card .card-content h6 span {
  color: #a7aab0;
  padding: 0 0 0 11px;
}

.card-content {
  padding: 20px 0px;
}

.card-content .price {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.card-content .price span {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #f5f5f5;
}

.card-content .price p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.crypto-card .card-img img:first-child {
  width: 100%;
  max-width: 42px;
}

.crypto-card .card-img img:last-child {
  width: 100%;
  max-width: 47%;
}

.trading-slide .swiper-pagination span.swiper-pagination-bullet {
  opacity: 1;
  width: 5px;
  height: 5px;
  background: #fae8f2 !important;
  border-radius: 20px;
}

.trading-slide .swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 10px !important;
  background: #e477b0 !important;
}

.trading-slide .swiper-pagination span.swiper-pagination-bullet {
  opacity: 1;
  width: 5px;
  height: 5px;
  background: #fae8f2 !important;
  border-radius: 20px;
}

.trading-slide .swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 10px !important;
  background: #e477b0 !important;
}

.serivce-cards {
  display: flex;
  flex-wrap: wrap;
  width: 48%;
  justify-content: space-between;
}

.service-card {
  width: 47%;
  background: rgba(34, 36, 39, 0.75);
  border-radius: 12px;
  padding: 30px 10px;
  margin: 18px 0;
}

.service-content {
  width: 52%;
  padding: 0 0 0 3%;
}

.service-card .sec-cion {
  width: fit-content;
  padding: 15px 12px;
  background: #323538;
  border-radius: 6px;
  margin: 0 auto;
  text-align: center;
}

.service-card .sec-cion img {
  width: 100%;
  max-width: 80%;
}

.service-card .sec-content {
  padding: 30px 0 0 0;
  text-align: center;
}

.service-card .sec-content span.sub-title {
  justify-content: center;
  font-size: 10px;
}

.service-card .sec-content p.para.center {
  font-size: 16px;
}

.service-card .sec-content a.cta-btn {
  margin: 10px auto 0px auto;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 10px #d21c7c57;
}

.service-card:hover .sec-cion {
  background: #d21c7c;
}

.services .row {
  align-items: center;
}

.service-content p.para {
  padding: 30px 0 0 0;
}

.steps {
  padding: 0 0 50px 0;
  text-align: center;
}

.steps span.sub-title {
  padding: 0 0 12px 0;
  display: block;
}

.service-card.Step-card {
  margin: 60px 20px 0 20px;
  background: rgba(34, 36, 39, 0.75);
  backdrop-filter: blur(2.5px);
  border-radius: 6px;
  padding: 30px 30px;
}

.service-card.Step-card .sec-content {
  text-align: left;
  padding: 11px 0 0 0;
}

.service-card.Step-card h3.h3heading {
  text-align: left;
  padding: 10px 0 20px 0;
}

.service-card.Step-card .sec-cion {
  margin: 0;
  border-radius: 50px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fae8f2;
}

.service-card.Step-card p.para.center {
  line-height: 24px;
}

.Cta-Section {
  background: #d21c7c;
  text-align: center;
  padding: 4% 0;
}

.Cta-Section .cta-btn {
  margin: 30px auto 0 auto;
  padding: 14px 23px;
}

.dynamic-table {
  padding: 8% 0;
  text-align: center;
}

.trading-table {
  width: 100%;
  padding: 20px 30px;
  background: rgba(34, 36, 39, 0.75);
  backdrop-filter: blur(2.5px);
  border-radius: 12px;
  margin: 60px 0px 0 0;
}

.trading-table .table {
  width: 100%;
}

.trading-table .table ul {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.trading-table .table .tbody ul li:nth-child(3) {
  text-align: end;
  width: 7%;
}

.trading-table .table .thead ul li:first-child {
  width: 36%;
  text-align: start;
}

.trading-table .table .thead {
  padding: 20px 0;
}

.trading-table .table .tbody ul li:first-child {
  width: 14%;
  text-align: start;
  display: flex;
  align-items: center;
}

.trading-table .table .tbody ul li:first-child img {
  width: 100%;
  max-width: 30px;
  margin: 0 14px 0 0;
}

.trading-table .table .tbody ul li {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #f5f5f5;
  width: 16%;
  margin: 0 7px;
}

.trading-table .table .thead ul li {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #a7aab0;
  width: 16%;
}

.trading-table .table .tbody ul {
  border-top: 1px solid #292c30;
  padding: 19px 0 0px 0;
}

.trading-table .table .tbody ul li:nth-child(2) {
  text-align: start;
  width: 10%;
  color: #a7aab0;
  font-weight: 400;
  width: 24% !important;
}

.trading-table .table .tbody ul li img {
  width: 100%;
  max-width: 80%;
}

.trading-table .table .tbody ul:last-child {
  border: none;
}

.dynamic-table a.cta-btn {
  margin: 60px auto 0 auto;
  padding: 18px 220px;
}

section.img-slide img {
  width: 100%;
}

section.img-slide .swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 40px;
  background: #d21c7c;
}

section.img-slide .swiper-pagination span.swiper-pagination-bullet {
  width: 24px;
  height: 12px;
  background: #292c30;
  border-radius: 12px;
  opacity: 1;
}

section.img-slide {
  padding: 0 0 70px 0;
}

section.img-slide .swiper-pagination {
  bottom: 15px;
}

.check-box label img {
  margin: 0 9px 0 0;
}

.toggle-icon {
  background: #17181a;
  border: 1px solid #323538;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  padding: 8px 10px;
}

.market-card {
  padding: 30px;
  background: rgba(34, 36, 39, 0.75);
  border-radius: 10px;
  height: 160px;
}

.market-card img {
  width: 50%;
  margin: 0 auto 10px auto;
  display: table;
  filter: grayscale(1);
  height: 100%;
  object-fit: contain;
}
.market-card .img {
  width: 100%;
  height: 50px;
}

.market-card h2.h3heading {
  margin-top: 10px;
}
.market-slide span.sub-title,
.market-slide p {
  text-align: center;
  justify-content: center;
}

.market-slide p {
  font-size: 12px;
}

.market-slide span.sub-title {
  margin-top: 19px;
}
.market-slide {
  padding: 60px 0;
  text-align: center;
}

.market-card:hover img {
  filter: none;
}
.market-slide .swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 40px;
  background: #d21c7c;
}

.market-slide .swiper-pagination span.swiper-pagination-bullet {
  width: 24px;
  height: 12px;
  background: #292c30;
  border-radius: 12px;
  opacity: 1;
}

.market-slide .swiper-pagination {
  position: relative;
  margin: 40px 0 0 0;
}
.market-slide .flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.market-slide .flex button.cta-btn {
  font-weight: 900;
  border: 1px solid;
  padding: 0px 7px;
  font-size: 12px;
}
.market-slide .swiper {
  margin: 20px 0 0 0;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .market-slide .flex {
    flex-wrap: wrap;
  }
  section.img-slide .container {
    padding: 0;
  }

  .market-slide .swiper-pagination span.swiper-pagination-bullet {
    width: 10px;
    height: 5px;
  }
  .market-slide .swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 10px;
  }
  section.img-slide .swiper-pagination span.swiper-pagination-bullet {
    width: 10px;
    height: 5px;
  }

  section.img-slide
    .swiper-pagination
    span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 16.67px;
  }

  section.img-slide {
    padding: 10px 0;
  }

  .dynamic-table a.cta-btn {
    padding: 13px 0;
    width: 100%;
    justify-content: center;
    border-radius: 0;
    margin: 40px 0;
  }

  .services .row {
    flex-direction: column-reverse;
  }

  .container {
    max-width: 100%;
    padding: 0 20px;
  }

  .serivce-cards,
  .service-content {
    width: 100%;
  }

  .hero-img {
    width: 100%;
    margin: 20px 0 0 0;
  }

  span.sub-title {
    font-size: 10px;
  }

  h1.h1Heading {
    font-size: 36px;
    line-height: 44px;
  }

  .banner .hero-content {
    padding: 0 20px;
  }

  .banner .hero-content a.cta-btn {
    width: 100%;
    justify-content: center;
  }

  .App {
    background: #17181a;
  }

  .crypto-card {
    background: #222427;
    display: flex;
    align-items: start;
    padding: 20px 20px;
    flex-wrap: wrap;
  }

  section.trading-slide {
    padding: 0 20px;
    margin: 0;
  }

  .crypto-card img:last-child {
    display: none;
  }

  .crypto-card .card-content {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }

  .crypto-card .card-img {
    margin: 0px 0px 7px 0;
    width: 100%;
  }
  .crypto-card .card-img svg {
    margin-left: auto;
  }
  .crypto-card .card-content h6 {
    flex-direction: column;
    display: flex;
  }

  .crypto-card .card-content h6 span {
    padding: 0;
  }

  .card-content .price {
    flex-direction: column;
    padding: 0;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .card-content .price span {
    font-size: 16px;
    font-weight: 400;
  }

  .crypto-card .card-img img:first-child {
    max-width: 29px;
    margin: 3px 0 0 0;
  }

  h2.h2heading {
    font-size: 32px;
  }

  .service-content {
    padding: 0;
  }

  .serivce-cards {
    flex-wrap: wrap;
  }

  .service-card {
    width: 100%;
    padding: 30px 40px;
  }

  .dynamic-table {
    padding: 14% 0 0 0;
  }

  .trading-table {
    padding: 20px 20px;
    width: 90%;
    margin: 30px auto 0 auto;
  }

  .trading-table .table {
    padding: 0 0 30px 0;
    overflow-x: scroll;
  }

  .trading-table .table ul li {
    flex: 0 0 51% !important;
    text-align: start !important;
    font-weight: 400 !important;
  }

  .trading-table .table .thead {
    width: 100%;
  }

  .trading-table .table ul {
    width: 100%;
  }

  .trading-table .table .tbody ul {
    padding: 8px 0;
  }

  .trading-table .table .tbody ul li:nth-child(2) {
    flex: 0 0 29% !important;
  }

  .trading-table .table .tbody ul li:first-child img {
    margin: 0 9px 0 0;
    max-width: 20px;
  }

  .trading-table .table .tbody ul li:first-child {
    flex: 0 0 39% !important;
  }

  .trading-table .table .thead ul li:first-child {
    flex: 0 0 80% !important;
  }

  .trading-table .table .thead ul li:last-child {
    padding: 0 0 0 90px;
  }

  .trading-table .table::-webkit-scrollbar {
    height: 8px;
  }

  .trading-table .table::-webkit-scrollbar-track {
    background: #323538;
    border-radius: 20px;
  }

  .trading-table .table::-webkit-scrollbar-thumb {
    background: #82868a;
    border-radius: 20px;
  }

  .dynamic-table .container {
    padding: 0;
  }

  section.steps {
    padding: 40px 0 0 0;
  }

  .service-card.Step-card {
    margin: 15px 0;
    padding: 20px 0px 20px 20px;
  }

  .steps .row {
    padding: 30px 0;
  }

  section.Cta-Section {
    padding: 64px 0;
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    width: 650px;
  }

  section.banner {
    padding: 30px 0px 30px -20px;
  }

  h1.h1Heading {
    font-size: 33px;
    line-height: 41px;
  }

  p.para {
    font-size: 15px;
  }

  .banner .hero-content {
    width: 320px;
  }

  span.sub-title {
    font-size: 12px;
    line-height: 21px;
  }

  section.trading-slide {
    margin: 0px 0 0 0;
  }

  .card-content {
    padding: 20px 0 0 0;
  }

  .serivce-cards {
    width: 100%;
  }

  .services .row {
    flex-direction: column-reverse;
  }

  .service-content {
    width: 100%;
    padding: 0;
  }

  .trading-table .table .tbody ul li {
    font-size: 11px;
  }

  .trading-table .table .tbody ul li:nth-child(2) {
    width: 14% !important;
    padding: 0 0 0 10px;
  }

  .trading-table .table .tbody ul li {
    margin: 0 12px;
  }

  .trading-table .table .tbody ul {
    padding: 7px 0;
  }

  .trading-table .table .tbody ul li:first-child img {
    max-width: 22px;
  }

  .trading-table .table .thead ul li {
    font-size: 12px;
  }

  .service-card.Step-card {
    padding: 20px 10px;
    margin: 40px 10px;
  }

  .service-card.Step-card h3.h3heading {
    padding: 0 0 10px 0;
    font-size: 15px;
  }

  .service-card.Step-card p.para.center {
    font-size: 13px;
  }

  footer .foot-logo {
    width: 100%;
    padding: 0 0 40px 0;
  }

  footer .row {
    flex-wrap: wrap;
  }

  footer .foot-links {
    padding: 0 0 0 10px;
    width: 25%;
  }

  footer .foot-logo .site-logo img {
    max-width: 36%;
  }

  footer .foot-links:last-child {
    padding: 0;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .container {
    max-width: 940px;
  }

  .currency-select .SelectInput-module_container__1RpWr {
    width: 190px;
  }

  header .site-logo .menu li a {
    font-size: 15px;
  }

  .currency-select {
    gap: 20px;
  }

  header .site-logo .menu li {
    padding: 8px 15px;
  }

  .banner .hero-content {
    padding: 0 0 0 50px;
  }

  .serivce-cards {
    width: 60%;
  }

  footer .foot-links {
    width: 25%;
  }

  footer .foot-links:last-child {
    padding: 0;
  }

  .service-card .sec-content a.cta-btn {
    padding: 10px 30px;
  }

  .trading-table .table .tbody ul li:nth-child(2) {
    width: 21% !important;
  }

  .trading-table .table .tbody ul li:nth-child(3) {
    width: 14%;
  }

  .rdk-portal {
    width: 18%;
    right: 193px;
    top: 83px !important;
  }

  .trading-table .table .tbody ul {
    padding: 8px 0;
  }

  .trading-table .table .tbody ul li:first-child img {
    max-width: 24px;
  }

  .trading-table .table .tbody ul li {
    font-size: 14px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .rdk-portal {
    right: 17%;
    width: 220px;
    top: 80px !important;
  }
}
