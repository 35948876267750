@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,700&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-shadow: none;
  transition: all 0.5s ease;
}

body {
  font-family: 'Inter', 'Roboto', sans-serif;
}

.App {
  background-image: url(assets/images/Desktop-bg.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

header {
  background: #17181A;
  padding: 30px 0;
  position: relative;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.row {
  display: flex;
}

header .row {
  align-items: center;
  justify-content: space-between;
}

header .site-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header .site-logo .menu {
  display: flex;
  align-items: center;
  padding: 0 0 0 30px;
}

header .site-logo .menu li {
  padding: 8px 21px;
}

header .site-logo .menu li a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #A7AAB0;
}

header .site-logo .menu li .sub-menu {
  position: absolute;
  width: 180px;
  background: #17181a;
  z-index: 999;
  left: -20px;
  padding: 10px 20px;
}


header .site-logo .menu li .sub-menu a {
  width: 100%;
  padding: 6px 0;
}

header .site-logo .menu li a:hover {
  color: rgb(210, 28, 124);
}

header .site-logo .menu li.active img {
  transform: rotate(180deg);
}

.currency-select {
  display: flex;
  position: relative;
  gap: 30px;
}

header .site-logo .menu li a img {
  margin: 0 0 0 20px;
}

.check-box input[type=checkbox] {
  width: 20px;
  height: 20px;
  accent-color: #d21c7c;
  margin: 0 10px 0 0;
  background: #FAFAFA;
  opacity: 0.2;
  border-radius: 3px;
}

.check-box label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #F5F5F5;
}

.check-box {
  display: flex;
  align-items: center;
}

.check-box input[type=checkbox]:checked {
  opacity: 1;
}

.cta-btn {
  padding: 10px 14px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  width: fit-content;
  align-items: center;
}

.cta-btn img {
  margin: 0 0 0 7px;
}

.sub-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #FF70BB;
}

.h1Heading {
  font-weight: 800;
  font-size: 52px;
  line-height: 56px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #F5F5F5;
  padding: 10px 0;
}

h2.h2heading {
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #F5F5F5;
}

.h3heading {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #F5F5F5;
  padding: 7px 0;
}

h2.h2heading span {
  color: #D21C7C;
}

.para {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #A7AAB0;
}

.cta-btn:hover {
  background: #17181a !important;
  box-shadow: 0 0px 14px #d21c7c40;
  transform: translateY(5px);
}

footer {
  padding: 4% 0;
  background: #222427;
}

footer .foot-logo {
  width: 40%;
}

footer .foot-links:last-child {
  width: 23%;
  padding: 0 0 0 100px;
}

footer .foot-logo .site-logo img {
  width: 100%;
  max-width: 60%;
}

footer .foot-logo p,
footer .foot-logo span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #A7AAB0;
  padding: 21px 30px 20px 0;
}

footer .foot-links h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #F5F5F5;
  padding: 0 0 12px 0;
}

footer .foot-links {
  padding: 0 20px;
  width: 20%;
}

footer .foot-links ul li {
  padding: 3px 0;
}

footer .foot-links ul li a {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #A7AAB0;
  width: 100%;
  padding: 5px 0;
  display: block;
}

footer .foot-links ul li a:hover {
  color: #D21C7C;
}

footer .foot-links:last-child ul {
  display: flex;
  justify-content: space-between;
}

footer .foot-links:last-child ul li a img {
  width: 100%;
  max-width: 21px;
}

footer .foot-links:last-child ul li {
  width: 22%;
  padding: 0;
}

footer .foot-links:last-child ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer .foot-links:last-child ul li a:hover img {
  transform: translateY(5px);
}

.mega-menu {
  position: absolute;
  width: 62%;
  background: #222427;
  left: 0;
  right: 0;
  margin: auto;
  top: 110px;
  padding: 40px 60px;
  z-index: 999;
}

.mega-menu .row .col4 {
  width: 30%;
}

.mega-menu .row .col8 {
  width: 70%;
}

.mega-menu .row .col8 ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px 0;
}

.mega-menu .row:nth-child(1) {
  padding: 0 0 30px 0;
  margin: 0 0 30px 0;
  border-bottom: 1px solid #323538;
  align-items: self-start;
}

.mega-menu .row ul li {
  width: 33%;
  padding: 0;
}

.mega-product {
  padding: 8px 21px;
  border-left: 1px solid #FF70BB;
}

.mega-product h2, body header .site-logo .menu li h2 a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #F5F5F5;
}

.mega-product p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #A7AAB0;
}

.mega-product span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  background: #404040;
  border-radius: 24px;
  padding: 7px 18px;
  width: fit-content;
  margin: 4px 0;
}


@media screen and (min-width: 320px) and (max-width: 767px) {
  .mega-product {
    padding: 8px 10px;
  }

  header .site-logo .menu li a {
    justify-content: space-between;
  }

  .row {
    flex-wrap: wrap;
  }

  .navbar {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #222427;
    z-index: 9999;
    left: -100%;
    padding: 0;
    overflow-y: scroll;
  }

  .navbar.active {
    left: 0;
  }

  .navbar .toggle-icon {
    width: fit-content;
    margin-left: auto;
  }

  header .site-logo .menu {
    flex-direction: column;
    align-items: self-start;
    padding-bottom: 10%;
  }

  header .site-logo .menu li {
    /* border-bottom: 1px solid #f5f5f524; */
    width: 100%;
    padding: 10px 0;
  }

  header .site-logo .menu li .sub-menu {
    position: unset;
    width: 100%;
    padding: 10px 18px;
    /* background: #222427; */
  }


  header .site-logo .menu li.active {
    border: none;
  }

  .currency-select {
    flex-direction: column;
    padding: 0;
    width: 100%;
  }

  .navbar>div,
  .navbar>ul {
    width: 100%;
    padding: 17px !important;
    gap: 15px;
  }

  .check-box label {
    width: 100%;
    justify-content: center;
    padding: 14px 0;
    background: #17181A;
    border-radius: 6px;
    font-family: 'Roboto';
  }

  header .site-logo {
    background: #17181A;
  }

  body .currency-select .SelectInput-module_container__1RpWr {
    width: 100%;
  }

  .currency-select a.cta-btn {
    width: 100%;
    justify-content: center;
    padding: 17px 0;
  }

  body .rdk-portal {
    z-index: 9999 !important;
    top: inherit !important;
    right: 0 !important;
    left: 20px !important;
    position: fixed !important;
    bottom: 30px;
    width: 89%;
  }

  footer .foot-logo {
    width: 100%;
  }

  footer .foot-links {
    width: 100%;
    padding: 30px 0 0 0;
  }

  footer .foot-logo p,
  footer .foot-logo span {
    width: 100%;
    padding: 22px 0;
  }

  footer .container {
    padding: 0 10px;
  }

  footer .foot-links:last-child {
    padding: 22px 0 20px 0;
    width: 100%;
  }

  footer .foot-links:last-child ul li {
    width: auto;
    margin: 0 9px 0 0px;
  }

  footer .foot-links:last-child ul {
    justify-content: start;
  }

  .toggle-icon {
    padding: 8px 10px;
    background: #17181A;
    border: 1px solid #323538;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
  }

  .toggle-icon:hover {
    background: #d21c7c;
  }
}


@media screen and (min-width: 768px) and (max-width: 1023px) {
  header .site-logo .menu li a {
    justify-content: space-between;
  }

  .navbar {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #222427;
    z-index: 9999;
    left: -100%;
    padding: 0;
  }

  .navbar.active {
    left: 0;
    overflow: scroll;
}
  .navbar .toggle-icon {
    width: fit-content;
    margin-left: auto;
  }

  header .site-logo .menu {
    flex-direction: column;
    align-items: self-start;
    padding-bottom: 10%;
  }

  header .site-logo .menu li {
    border-bottom: 1px solid #f5f5f524;
    width: 100%;
    padding: 10px 0;
  }

  header .site-logo .menu li .sub-menu {
    position: unset;
    width: 100%;
    padding: 10px 18px;
    margin: 20px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px 0;
  }

  header .site-logo .menu li a {
    justify-content: space-between;
    padding: 0 11px;

  }

  header .site-logo .menu li .sub-menu li {
    width: 50%;
    border: none;
  }

  header .site-logo .menu li.active {
    border: none;
  }

  .navbar>div,
  .navbar>ul {
    width: 100%;
    padding: 17px !important;
    gap: 15px;
  }

  .check-box label {
    width: 100%;
    justify-content: center;
    padding: 14px 0;
    background: #17181A;
    border-radius: 6px;
    font-family: 'Roboto';
  }

  header .site-logo {
    background: #17181A;
  }

  .currency-select {
    flex-direction: column;
    /* position: absolute; */
    padding: 17px 0;
    width: 100%;
  }

  body .currency-select .SelectInput-module_container__1RpWr {
    width: 100%;
  }

  .currency-select a.cta-btn {
    width: 100%;
    justify-content: center;
    padding: 17px 0;
  }

  body .rdk-portal {
    z-index: 9999 !important;
    top: inherit !important;
    right: 0 !important;
    left: 23px !important;
    position: fixed !important;
    bottom: 236px;
    width: 94%;
  }

  .currency-select a.cta-btn {
    width: 100%;
    justify-content: center;
    padding: 17px 0;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .mega-menu {
    padding: 20px;
    width: 90%;
  }

  .mega-product h2, body header .site-logo .menu li h2 a {
    font-size: 15px;
}
  .mega-menu ul li {
    padding: 0 !important;
  }

  .mega-product p {
    font-size: 12px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1650px) {

  .mega-product p {
    font-size: 13px;
}

.mega-product span {
    font-size: 11px;
}
.mega-menu {
  width: 70%;
  padding: 2% 2%;
}

h2.h2heading {
  font-size: 32px;
}

.mega-menu .row .col4 {
  width: 25%;
}

.mega-menu .row .col8 {
  width: 80%;
}
}