.currency-select select {
    padding: 10px 30px 10px 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #F5F5F5;
    background: url(../../assets/images/arrow.svg), #17181A;
    border: 1px solid #323538;
    border-radius: 6px;
    appearance: none;
    background-size: 10%;
    outline: none;
    background-repeat: no-repeat;
    background-position: 91% 53%;
}

.currency-select .SelectInput-module_container__1RpWr {
    padding: 10px 20px;
    background: #17181A;
    border: 1px solid #323538;
    border-radius: 6px;
    width: 200px;
    height: 50px;
}

.rdk-portal {
    transform: none !important;
    left: inherit !important;
    right: 490px;
    top: 100px !important;
    background: #17181a;
    padding: 10px 10px;
    color: #fff;
    width: 11.5%;
    will-change: inherit !important;
}

.rdk-portal ul li {
    padding: 9px 0;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #F5F5F5 !important;
    text-transform: capitalize;
}

.rdk-portal ul li img {
    margin: 0 10px 0 0;
}

.currency-select .SelectInput-module_container__1RpWr .SelectInput-module_prefix__uL_kt.reaselct-input-value,
.currency-select .SelectInput-module_container__1RpWr input {
    text-transform: capitalize;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #F5F5F5;
    text-overflow: unset;
    outline: none !important;
}

.currency-select .SelectInput-module_container__1RpWr .SelectInput-module_prefix__uL_kt.reaselct-input-value img {
    margin: 0 11px 0px 0;
}

.currency-select .SelectInput-module_suffix__1FaXH button svg {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(329deg) brightness(109%) contrast(101%);
}

.currency-select .SelectInput-module_suffix__1FaXH button {
    color: #ffff;
    filter: brightness(60);
}

.currency-select .SelectInput-module_suffix__1FaXH {
    margin: 0 0 0 10px;
}

.currency-select .SelectInput-module_container__1RpWr.SelectInput-module_single__1KuWP .SelectInput-module_input__ib6_X {
    text-overflow: unset !important;
}

.reaselct-menu {
    will-change: inherit !important;
    width: 100% !important;
}

.reaselct-menu::-webkit-scrollbar {
    width: 10px;
}

.reaselct-menu::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.reaselct-menu::-webkit-scrollbar-thumb {
    background: #d21c7c;
}

.reaselct-menu::-webkit-scrollbar-thumb:hover {
    background: #555;
}